<template>
  <div>
    <h4>
      Address
    </h4>
    <div
      class="row"
    >
      <div class="col-md-6">
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >{{ labels['address'] }}</label>
          <input
            v-model="address.address"
            type="text"
            class="form-control"
          >
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >{{ labels['address_2'] }}</label>
          <input
            v-model="item.address_2"
            type="text"
            class="form-control"
          >
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >{{ labels['postal_code'] }}</label>
          <input
            v-model="address.postal_code"
            type="text"
            class="form-control"
          >
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >{{ labels['city'] }}</label>
          <input
            v-model="address.city"
            type="text"
            class="form-control"
          >
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >{{ labels['address_country'] }}</label>
          <input
            v-model="address.country"
            type="text"
            class="form-control"
          >
        </div>
        <hr>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >{{ labels['phone'] }}</label>
          <input
            v-model="item.phone"
            type="text"
            class="form-control"
          >
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >{{ labels['mobile_phone'] }}</label>
          <input
            v-model="item.mobile_phone"
            type="text"
            class="form-control"
          >
        </div>
        <div class="mb-1">
          <label
            for=""
            class="form-label"
          >{{ labels['professional_email'] }}</label>
          <input
            v-model="item.professional_email"
            type="text"
            class="form-control"
          >
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-1 h-100">
          <label
            for=""
            class="form-label"
          >{{ labels['location_in_map'] }}</label>
          <vue-google-autocomplete
            id="map"
            :value="getFullAddress()"
            classname="form-control"
            placeholder="Search for a location"
            types=""
            :disabled="!manualInstitution"
            @placechanged="getAddressData($event)"
          />
          <div
            id="departmentMap"
            class="mt-2"
            style="width:100%;height:80%;"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'

export default {
  components: {
    VueGoogleAutocomplete,
  },
  props: {
    address: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    showAddress: {
      type: [Boolean, String, Number],
      required: true,
    },
    manualInstitution: {
      type: Boolean,
      required: true,
    },
    labels: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log(this.address)
    if (!this.manualInstitution && this.address.latitude && this.address.longitude) {
      this.changeMap(this.address)
    }
  },
  methods: {
    changeMapAndAdress(department) {
      if (this.showAddress) {
        if (department) {
          this.changeMap(department)
          this.getFullAddress()
        }
      }
    },
    changeMap(data) {
      // The location
      const uluru = { lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) }
      // The map, centered at Uluru
      const map = new google.maps.Map(
        document.getElementById('departmentMap'),
        {
          zoom: 13,
          center: uluru,
        },
      )

      // The marker, positioned at Uluru
      const marker = new google.maps.Marker({
        position: uluru,
        map,
      })
    },
    getFullAddress() {
      if (this.address.address && this.address.city && this.address.country) {
        return `${this.address.address}, ${this.address.city}, ${this.address.country}`
      }

      return ''
    },
    getAddressData(data) {
      this.address.latitude = data.latitude
      this.address.longitude = data.longitude
      this.address.country = data.country
      this.address.city = data.locality
      this.address.address = data.route
      this.$forceUpdate()
      this.changeMap(this.address)
    },
  },
}
</script>

<style>

</style>
