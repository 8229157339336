<template>
  <div class="card">
    <div
      v-b-toggle="`collapseInstitutionDepartment`"
      class="card-header"
    >
      <h3 class="cart-title">
        <a data-action="collapse">Institution and department</a>
      </h3>
      <div class="heading-elements">
        <ul class="list-inline mb-0">
          <li>
            <a data-action="collapse"><i data-feather="chevron-down" /></a>
          </li>
        </ul>
      </div>
    </div>
    <b-collapse
      :id="`collapseInstitutionDepartment`"
      visible
    >
      <div class="card-content collapse show">
        <div class="card-body">
          <div class="row">
            <div
              class="mb-1 col-12"
              :class="requiredFields ? 'form-group required' : ''"
            >
              <label
                for=""
                class="form-label d-block"
              >{{ labels['search_institution'] }} <a
                class="float-end text-danger"
                @click="toggleInstitution"
              ><i data-feather="alert-triangle" /> Institution not listed</a></label>
              <v-select
                v-if="!manualInstitution"
                v-model="item.institution"
                label="name"
                :options="institutions"
                :get-option-key="option => option.id"
                @search="onSearch({ name: $event }, 'institutions/filter')"
              />
              <input
                v-else
                v-model="item.institution_name"
                type="text"
                class="form-control"
                placeholder="Name of the institution"
              >
            </div>
            <div
              class="mb-1"
              :class="{ 'form-group required': requiredFields, 'col-6': manualInstitution && showCountry }"
            >
              <label class="form-label">
                {{ labels['department'] }}
              </label>
              <v-select
                v-if="!manualInstitution"
                v-model="item.department"
                label="name"
                :disabled="!item.institution"
                :options="item.institution ? item.institution.departments : []"
                :get-option-key="option => option.id"
                :placeholder="!item.institution ? 'Choose a institution' : 'Select a department'"
                @input="changeMapAndAdress(item.department)"
              />
              <input
                v-else
                v-model="item.department_name"
                type="text"
                class="form-control"
              >
            </div>
            <div
              v-if="manualInstitution && showCountry"
              class="mb-1 col-6"
            >
              <label class="form-label">
                {{ labels['country'] }}
              </label>
              <input
                v-model="item.country_name"
                type="text"
                class="form-control"
              >
            </div>
          </div>
          <hr>
          <Address
            v-if="showAddress && labels.length !== 0"
            :labels="labels"
            :address="item"
            :show-address="showAddress"
            :manual-institution="manualInstitution"
            :item="item"
          />
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import Vue from 'vue'
import { BCollapse } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import Address from './Address.vue'

export default {
  components: {
    BCollapse,
    vSelect,
    Address,
  },
  props: {
    item: { type: Object, required: false, default: () => {} },
    showAddress: { type: [Boolean, Number, String], required: false, default: false },
    showCountry: { type: Boolean, required: false, default: false },
    requiredFields: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      selectedInstitution: null,
      manualInstitution: false,
      selectedDepartment: null,
      locationChanged: false,
      labels: {},
    }
  },
  computed: {
    ...mapGetters({
      institutions: 'institutions/institutions',
    }),
  },
  async mounted() {
    this.manualInstitution = this.item.institution_name != null && this.item.department_name != null && this.item.id != null
    if (this.showAddress) {
      if (this.item.id) {
        if (this.item.institution && this.item.department) {
          this.selectedInstitution = this.item.institution
          this.selectedDepartment = this.item.department
        }

        if (this.item.department && !this.item.address) {
          this.changeMapAndAdress(this.item.department)
        }
      }
    }

    try {
      const { data } = await Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/section-labels/showLabel/components.institutions_and_department`)
      this.labels = data.data
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    changeMapAndAdress(department) {
      this.item.city = department.city
      this.item.country = department.country
      this.item.address = department.address
      this.item.postal_code = department.postal_code
    },
    toggleInstitution() {
      this.item.institution_name = null
      this.item.department_name = null
      this.selectedInstitution = null
      this.selectedDepartment = null
      this.item.country_name = ''
      this.item.institution = []
      this.item.department = []

      this.manualInstitution = !this.manualInstitution
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
  },
}
</script>
